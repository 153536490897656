<template>
    <form @submit.prevent="update">
        <provider-emails :provider-id="provider.id" />
    </form>
</template>

<script>
import { mapState } from 'vuex'

const ProviderEmails = () => import('@/../../shared/components/Provider/ProviderEmails')

export default {
    name: 'ProviderSettingsEmailTab',
    components: {
        ProviderEmails
    },
    computed: {
        ...mapState('providerModule', ['provider'])
    }
}
</script>